import { PERSON } from './person'

export const SPACE = `{
  id
  owner
  fingerprints {
    device_modelname
    device_os
    ip_adress
    browser_name
    __typename
  }
  os
  additional_requested_data
  newsletter
  language
  all_challenges_unlocked
  progress
  mail_stored
  partner_id
  authorized_create
  authorized_read
  authorized_update
  authorized_delete
  career_id
  partner_user_id
  person ${PERSON}
  ref_link
  referrer_link
  matching_score_to_space_career
  related_spaces
  invited_from_user_id
  invitation_id
  context
  callback_url
  expiresAt
  created_with_service
  invitation_reminder_at
  reset_expiredAt_at
  comment {
    person_id
    owner
    message
    createdAt
    __typename
  }
  createdAt
  updatedAt
  soft_deleted
  lastUpdatedBy
  __typename
}`
