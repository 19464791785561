import { useQuery } from 'react-query'
import { HomeIcon } from '@heroicons/react/20/solid'
import React, { useState } from 'react'

import { query } from '../api'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import { JSONTree } from 'react-json-tree'
import Button from '../components/Button'

const pages = [
  { name: 'System', to: '/system', current: false },
  { name: 'Dimensions', to: '#', current: true }
]

const Dimensions = () => {
  const { data: dimensions, isLoading } = useQuery({
    queryKey: ['dimensions'],
    queryFn: () =>
      query({ query: 'getSystem', variables: { id: 'DIMENSIONS' } }),
    select: (res) => Object.values(JSON.parse(res.data))
  })

  const [isEditing, setIsEditing] = useState({})

  const generateEditableFields = (dimensionObj) => {
    const extractStrings = (obj, prefix = '') => {
      const result = {}
      for (const key in obj) {
        const value = obj[key]
        const newKey = prefix ? `${prefix}.${key}` : key
        if (typeof value === 'string') {
          result[newKey] = value
        } else if (typeof value === 'object' && value !== null) {
          Object.assign(result, extractStrings(value, newKey))
        }
      }
      return result
    }

    const inputs = extractStrings(dimensionObj)
    return Object.keys(inputs).map((key) => (
      <div key={key} className='mt-8 border-b border-gray-900/10 pb-8'>
        <label
          htmlFor={key}
          className='block text-sm font-medium leading-6 text-gray-900'
        >
          {key}
        </label>
        <div className='mt-2'>
          <textarea
            id={key}
            name={key}
            rows={4}
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            defaultValue={inputs[key]}
          />
        </div>
      </div>
    ))
  }

  if (isLoading) {
    return <Loader loadingText={'Dimensions werden geladen ...'} />
  }

  return (
    <div>
      <nav className='flex mb-8' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to={'/'} className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <svg
                  className='h-5 w-5 flex-shrink-0 text-gray-300'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                >
                  <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                </svg>
                <Link
                  to={page.to}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <header className='mb-8'>
        <h1 className='text-3xl font-bold leading-tight tracking-tight text-gray-900'>
          Dimensions
        </h1>
      </header>
      <ul className='grid grid-cols-1 gap-6 sm:grid-cols-2'>
        {dimensions.map((d) => (
          <div
            key={d.property.de}
            className='w-full p-4 bg-white rounded-lg border-slate-200 border'
          >
            <h2 className='text-xl font-bold text-gray-900'>{d.property.de}</h2>
            {isEditing[d?.property?.de] === true ? (
              <React.Fragment>
                {generateEditableFields(d)}

                <div className='flex flex-row justify-end pt-8 pb-4'>
                  <Button.PrimaryXL
                    text='Aktualisieren'
                    className='bg-green-600 hover:bg-green-500'
                    onClick={() => alert('TODO')}
                  />
                </div>
              </React.Fragment>
            ) : (
              <div>
                <JSONTree data={d} />
                <div className='flex flex-row justify-end pt-8 pb-4'>
                  <Button.PrimaryXL
                    text='Bearbeiten'
                    onClick={() =>
                      setIsEditing({ ...isEditing, [d.property.de]: true })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </ul>
    </div>
  )
}

export default Dimensions
